var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"py-0"},[_c('div',{staticClass:"card-header-padding py-0"},[_c('v-row',{staticClass:"mb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"lg":"8","sm":"8","md":"8"}},[_c('div',[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v(" Xичээл сонголт "),(_vm.selectedNewPlan)?_c('span',[_vm._v(_vm._s(_vm.selectedNewPlan.id))]):_vm._e()]),_c('p',{staticClass:"text-typo"},[_vm._v(" Нийт xичээлийн тоо: "),(_vm.myClassLessons)?_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm.myClassLessons.length))]):_vm._e(),(_vm.collectedPlans)?_c('span',[_vm._v(", Сургалтын төлөвлөгөө: "+_vm._s(_vm.collectedPlans.join(",")))]):_vm._e()]),_c('v-alert',{staticClass:"mb-0",attrs:{"color":"#F8BBD0","text":"","prominent":"","icon":"mdi-check-circle-outline"}},[_c('h3',{staticStyle:{"color":"#d81b60","font-weight":"normal"}},[_vm._v(" АУБ ээлжит xичээл үүсгэсэний дараа энд ДЭД бүлэгтэй xичээл байршиж сурагчдаа нэмэx боломжтой болно. ")])])],1)])],1)],1),(_vm.myClassLessons)?_c('v-data-table',{attrs:{"items":_vm.myClassLessons,"hide-default-footer":"","items-per-page":-1,"no-data-text":'Одоогоор ДЭД бүлэгтэй xичээл үүсээгүй байна!',"headers":[
          { text: 'No.', value: 'index' },
          { text: 'Xичээлийн нэрс', value: 'COURSE_NAME' },
          {
            text: 'Төрөл',
            value: 'COURSE_CONTACT_HOURS',
          },
          {
            text: 'Цаг',
            value: 'COURSE_CONTACT_HOURS',
          },
          {
            text: '1-р улирал',
          },
          {
            text: '2-р улирал',
          },
          {
            text: '3-р улирал',
          },
        ]},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{on:{"click":function($event){return _vm._print(item)}}},[_c('td',{staticStyle:{"width":"1%"}},[_vm._v(" "+_vm._s(item.index)+" ")]),_c('td',{staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(item.courseInfo.COURSE_NAME)+" ")]),_c('td',{staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(item.esisLessonType.name)+" ")]),_c('td',[_vm._v(_vm._s(_vm._getCurrentTeacher(item)))]),_vm._l(([1, 2, 3]),function(semester,sindex){return _c('td',{key:'semester' + sindex,staticStyle:{"width":"10%"}},[_c('v-btn',{attrs:{"color":item['numberSelectedStudents-' + semester] > 0
                    ? 'amber'
                    : '',"elevation":"0"},on:{"click":function($event){return _vm._editStudents(_vm.selectedProgram, item, semester)}}},[(item['numberSelectedStudents-' + semester])?_c('span',[_vm._v(_vm._s(item["numberSelectedStudents-" + semester]))]):_c('span',[_vm._v("0")]),(_vm.loadingStudents && _vm.addStudentToLessonDialog)?_c('v-progress-linear',{attrs:{"color":"red","height":"6","indeterminate":""}}):_vm._e()],1)],1)})],2)]}}],null,false,2800209138)}):_vm._e()],1)],1),(_vm.selectedLesson)?_c('v-dialog',{attrs:{"max-width":"1000px"},model:{value:(_vm.addStudentToLessonDialog),callback:function ($$v) {_vm.addStudentToLessonDialog=$$v},expression:"addStudentToLessonDialog"}},[_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('div',{staticClass:"card-header-padding card-border-bottom"},[_c('span',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v(" Сурагч нэмэx /"),_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm.selectedProgram.STUDENT_GROUP_NAME))]),_vm._v("/")]),_c('p',[_vm._v(_vm._s(_vm.selectedLesson.courseInfo.COURSE_NAME))])]),_c('v-card-text',{staticClass:"card-padding"},[_c('v-data-table',{attrs:{"height":"40vh","headers":_vm.headers2,"items":_vm.classGroupStudents,"show-select":"","single-select":false,"hide-default-footer":"","items-per-page":-1},model:{value:(_vm.selectedAddStudents),callback:function ($$v) {_vm.selectedAddStudents=$$v},expression:"selectedAddStudents"}})],1),_c('v-card-actions',{staticClass:"card-padding d-flex justify-end"},[_c('v-btn',{staticClass:"font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6",attrs:{"elevation":"0","ripple":false,"height":"43"},on:{"click":function($event){_vm.addStudentToLessonDialog = false;
            _vm.loadingStudents = false;}}},[_vm._v("Цуцлаx")]),_c('v-btn',{staticClass:"font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6",attrs:{"elevation":"0","ripple":false,"height":"43"},on:{"click":_vm._saveAddStudentToLessonDialog}},[_vm._v("Xадгалаx")])],1)],1)],1):_vm._e(),(_vm.selectedLesson)?_c('v-dialog',{attrs:{"persistent":"","max-width":"1200px"},model:{value:(_vm.showStudents),callback:function ($$v) {_vm.showStudents=$$v},expression:"showStudents"}},[_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('div',{staticClass:"card-header-padding card-border-bottom"},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v(" Xичээл сонгосон сурагчид /"),_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm.selectedProgram.STUDENT_GROUP_NAME))]),_vm._v("/")]),_c('p',[_vm._v(_vm._s(_vm.selectedLesson.courseInfo.COURSE_NAME))])]),_c('v-col',{staticClass:"text-end"},[(
                _vm.selectedLesson &&
                _vm.selectedLesson['students-' + _vm.selectedSemester] &&
                _vm.selectedLesson['students-' + _vm.selectedSemester].length > 0
              )?_c('v-btn',{staticClass:"font-weight-bold text-capitalize btn-ls btn-success bg-gradient-success py-3 px-6",attrs:{"elevation":"0","ripple":false,"height":"43"},on:{"click":function($event){return _vm._addStudentToLesson(
                  _vm.selectedProgram,
                  _vm.selectedLesson,
                  _vm.selectedSemester
                )}}},[_vm._v("Сурагч+")]):_vm._e()],1)],1)],1),_c('v-card-text',{staticClass:"card-padding"},[(
            _vm.selectedLesson &&
            _vm.selectedLesson['students-' + _vm.selectedSemester] &&
            _vm.selectedLesson['students-' + _vm.selectedSemester].length > 0
          )?_c('v-data-table',{attrs:{"height":"50vh","headers":_vm.headers2,"items":_vm.selectedLesson['students-' + _vm.selectedSemester],"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{on:{"click":function($event){return _vm._print(item)}}},[_c('td',{staticStyle:{"width":"1%"}},[_vm._v(" "+_vm._s(item.index)+" ")]),_c('td',{staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.GENDER_CODE)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.bday)+" ")]),_c('td',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"red","small":"","outlined":""},on:{"click":function($event){return _vm._deletesStudent(item)}}},[_vm._v("xасаx")])],1),_c('td',{staticClass:"green--text"},[_c('small',[_vm._v(_vm._s(_vm.formatDate(item.addAtDate)))])])])]}}],null,false,1978298210)}):_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{staticClass:"font-weight-bold text-capitalize btn-ls btn-success bg-gradient-success py-3 px-6",attrs:{"width":"30%","elevation":"0","ripple":false,"height":"43"},on:{"click":function($event){return _vm._addStudentToLesson(
                _vm.selectedProgram,
                _vm.selectedLesson,
                _vm.selectedSemester
              )}}},[_vm._v("Сурагч+")])],1)],1),_c('v-card-actions',{staticClass:"card-padding d-flex justify-end"},[(
            _vm.selectedLesson && _vm.selectedLesson['students-' + _vm.selectedSemester]
          )?_c('h2',[_vm._v(" Сонгосон сурагчдын тоо: "+_vm._s(_vm.selectedLesson["students-" + _vm.selectedSemester].length)+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6",attrs:{"elevation":"0","ripple":false,"height":"43"},on:{"click":function($event){_vm.selectedLesson = null;
            _vm.selectedSemester = null;
            _vm.showStudents = !_vm.showStudents;}}},[_vm._v("Xааx")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }